<template>
    <el-dialog 
            :modal-append-to-body="false" 
            title="HR电话" 
            :visible.sync="dialogVisible" 
            width="20%"
            center
            @close="close"
        >
            <div class="cus_body">
                <el-input  placeholder="HR联系电话" v-model="recruitPhone"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="save" :loading="load">保存</el-button>
            </span>
        </el-dialog>
</template>

<script>
    import { addHrtel } from '@/api/mobileWeb.js';

    export default {
        data(){
            return {
                dialogVisible: false,
                recruitPhone: '',
                load: false,
                type: 'add',
            }
        },
        methods:{
            init(type,recruitPhone){
                if(type == 'edit'){
                    this.recruitPhone = recruitPhone;
                };
                this.dialogVisible = true;
            },
            close(){
                this.recruitPhone = '';
                this.dialogVisible = false;
            },
            save(){
                if(!this.recruitPhone || !(/^1[3456789]\d{9}$/.test(this.recruitPhone))){
                    this.$message.error('请输入正确的HR联系电话！');
                    return;
                };
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                    recruitPhone: this.recruitPhone
                };
                this.load = true;
                try{
                    addHrtel(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.$emit('success');
                            this.load = false;
                            this.close();
                        }else{
                            this.$message.success(res.message);
                            this.load = false;
                        }
                    }).catch(err=>{
                        this.load = false;
                    })
                }catch(err){
                    this.load = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
</style>