<template>
    <div>
        <el-dialog 
            :modal-append-to-body="false" 
            :title="type == 'add' ? '添加招聘' : '编辑招聘'" 
            :visible.sync="dialogVisible" 
            width="50%"
            center
            @close="close"
        >
            <div class="cus_body">
                <el-form
                        :model="formInfo"
                        ref="recruForm"
                        label-width="100px"
                        class="form_con"
                        :rules="rules"
                    >
                    <el-form-item label="职位名称" prop="position">
                        <el-input  placeholder="请输入职位名称" v-model="formInfo.position"></el-input>
                    </el-form-item>
                    <el-form-item label="行业" prop="positionIndustryId">
                        <el-select placeholder="请选择行业" v-model="formInfo.positionIndustryId">
                            <el-option 
                                :label="item.industryName"
                                :value="item.industryId" 
                                v-for="(item,index) in $store.state.tradeList" 
                                :key="index"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位描述">
                        <el-input type="textarea" size="medium" :rows="6" placeholder="请输入职位描述" v-model="formInfo.requirement"></el-input>
                    </el-form-item>
                    <el-form-item label="经验要求" prop="experience">
                        <el-select placeholder="请选择经验要求" v-model="formInfo.experience">
                            <el-option 
                                v-for="(item,index) in experienceArray"
                                :key="index"
                                :label="item.title" 
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="最低学历" prop="education">
                        <el-select placeholder="请选择最低学历" v-model="formInfo.education">
                            <el-option 
                                v-for="(item,index) in educatList"
                                :key="index"
                                :label="item.title" 
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="薪资范围" prop="minSalary">
                        <el-select style="width: 40%;margin-right: 12px" placeholder="请选择薪资范围" v-model="formInfo.minSalary">
                            <el-option
                                v-for="(item,index) in multiArray[0]"
                                :key="index"
                                :label="item" 
                                :value="item"
                            ></el-option>
                        </el-select>
                        <el-select style="width: 40%" placeholder="请选择薪资范围" v-model="formInfo.maxSalary">
                            <el-option
                                v-for="(item,index) in multiArray[1]"
                                :key="index"
                                :label="item" 
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位关键词">
                        <div class="tag_con d-flex">
                            <el-button type="primary" @click="addTag">添加标签</el-button>
                            <div class="tags" 
                                v-for="(item,index) in keyWord" 
                                :key="index"
                            >
                                {{item}}
                                <div class="del" @click="zwDel(index)">
                                    <i class="el-icon-error"></i>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="工作地址" prop="detailedAddress">
                        <el-input id="myMapsearch" placeholder="请输入工作地址" v-model="formInfo.detailedAddress" @change="theLocation"></el-input>
                        <div
                            id="myMap"
                            style="width: 100%; height: 400px; margin-top: 10px"
                        ></div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" :loading="load" @click="save">保存</el-button>
            </span>
        </el-dialog>


        <el-dialog 
            :modal-append-to-body="false" 
            title="职位关键词" 
            :visible.sync="zwPop" 
            width="20%"
            center
            @close="zwClose"
        >
            <div class="cus_body">
                <el-input  placeholder="请输入职位关键词" v-model="zwTxt"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="zwClose">取消</el-button>
                <el-button type="primary" @click="zwSave">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    let ac;
    import { educatList,experienceArray,multiArray } from './data.js';
    import { addRecruirt } from '@/api/mobileWeb.js';

    export default{
        data(){
            const checkSalary = (rule, value, callback) => {
                if (!value || !this.formInfo.maxSalary) {
                    return callback(new Error('请选择薪资范围'));
                }else{
                    return callback();
                }
            };
            return {
                type: 'add',
                dialogVisible: false,
                uploadUrl:this.$store.state.uploadingUrl,
                educatList,
                experienceArray,
                multiArray,
                keyWord: [],
                zwTxt: '',
                formInfo:{
                    position: '',
                    positionIndustryId: '',
                    requirement: '',
                    experience: '',
                    education: '',
                    minSalary: '3',
                    maxSalary: '4',
                    detailedAddress:'',
                },
                zwPop: false,
                map: {},
                local: {},
                rules: {
                    position: [{ required: true, message: "请输入职位名称", trigger: "blur" }],
                    positionIndustryId: [{required: true, message: "请选择行业", trigger: 'change' }],
                    experience: [{required: true, message: "请选择经验要求", trigger: 'change' }],
                    education: [{required: true, message: "请选择学历要求", trigger: 'change' }],
                    minSalary: [{required: true, validator:checkSalary, trigger: 'change' }],
                    detailedAddress: [{ required: true, message: "请输入工作地址", trigger: "blur" }]
                },
                accuracy: 116.404,
                dimension: 39.915,
                recruitAddress: '',
                load: false,
                recruitId: '',
            }
        },
        methods:{
            init(type,data){
                this.type = type;
                this.dialogVisible = true;
                if(type == 'add'){
                    this.$nextTick(()=>{
                        this.createdMap();
                    })
                }else{
                    this.recruitId = data.recruitId;
                    this.formatData(data);
                }
            },
            close(){
                this.formInfo = {
                    position: '',
                    positionIndustryId: '',
                    requirement: '',
                    experience: '',
                    education: '',
                    minSalary: '3',
                    maxSalary: '4',
                    detailedAddress:'',
                };
                this.recruitAddress = '';
                this.keyWord = [];
                this.load = false;
                ac.removeEventListener("onconfirm", this.setValue);
                this.$refs.recruForm.resetFields();
                this.dialogVisible = false;
            },
            addTag(){
                this.zwPop = true;
            },
            zwClose(){
                this.zwTxt = '';
                this.zwPop = false;
            },
            zwSave(){
                if(!this.zwTxt){
                    this.$message.error('请输入关键词！');
                    return;
                };
                this.keyWord.push(this.zwTxt);
                this.zwClose();
            },
            zwDel(idx){
                this.keyWord.splice(idx, 1);
            },
            createdMap() {
                let navigationControl = new BMap.NavigationControl({
                    anchor: BMAP_ANCHOR_TOP_LEFT,
                    type: BMAP_NAVIGATION_CONTROL_LARGE,
                    enableGeolocation: true,
                });
                this.map = new BMap.Map("myMap");
                let point = new BMap.Point(this.accuracy, this.dimension);
                this.map.centerAndZoom(point, 18);
                this.map.addControl(navigationControl);
                this.map.addOverlay(new BMap.Marker(point));

                ac = new BMap.Autocomplete({
                    input: "myMapsearch",
                    location: this.map,
                });
                ac.setInputValue(this.formInfo.detailedAddress);
                ac.addEventListener("onconfirm", this.setValue);
            },
            setValue(e) {
                const {
                    item: { value },
                } = e;
                this.formInfo.detailedAddress = `${value.city}${value.district}${value.business}`;
                this.recruitAddress = `${value.city}${value.district}`;
            },
            myFun() {
                if(this.local.getResults()){
                    let pp = this.local.getResults().getPoi(0).point;
                    this.map.centerAndZoom(pp, 18);
                    this.map.addOverlay(new BMap.Marker(pp));
                    this.formInfo.gps = JSON.stringify(pp);
                    this.dimension = pp.lat;
                    this.accuracy = pp.lng;
                }
            },
            theLocation(val) {
                this.map.clearOverlays();
                this.local = new BMap.LocalSearch(this.map, {
                    onSearchComplete: this.myFun,
                });
                this.local.search(val);
            },
            formatData(data){
                let { position,positionIndustryId,requirement,experience,education,minSalary,maxSalary,detailedAddress,accuracy,dimension,recruitAddress,keyWord } = data;
                this.formInfo = {
                    position,
                    positionIndustryId,
                    requirement,
                    experience,
                    education,
                    minSalary: minSalary/1000/100,
                    maxSalary: maxSalary/1000/100,
                    detailedAddress,
                };
                if(keyWord){
                    this.keyWord = keyWord.split(',');
                };
                this.accuracy = accuracy || 116.404;
                this.dimension = dimension || 39.915;
                this.recruitAddress = recruitAddress;
                this.$nextTick(()=>{
                    this.createdMap();
                })
            },
            save(){
                this.$refs.recruForm.validate((valid) => {
                    if(valid){
                        let opts = {
                            companyId: localStorage.getItem('companyId'),
                            ...this.formInfo,
                            accuracy: this.accuracy,
                            dimension: this.dimension,
                            recruitAddress: this.recruitAddress || this.formInfo.detailedAddress,
                            keyWord: this.keyWord.join(','),
                            maxSalary: Number(this.formInfo.maxSalary)*1000*100,
                            minSalary: Number(this.formInfo.minSalary)*1000*100,
                        };
                        if(this.type == 'edit'){
                            opts.recruitId = this.recruitId;
                        };
                        this.load = true;
                        try{
                            addRecruirt(opts).then(res=>{
                                if(res.code == 200){
                                    this.$message.success(res.message);
                                    this.$emit('success');
                                    this.load = false;
                                    this.close();
                                }else{
                                    this.$message.success(res.message);
                                    this.load = false;
                                }
                            }).catch(err=>{
                                this.load = false;
                            })
                        }catch(err){
                            this.load = false;
                        }
                    }
                })
            },
        },
        mounted(){},
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
.mar_b{
    margin-bottom: 10px;
}
.tag_con{
    flex-wrap: wrap;
    align-items: center;
}
.tags{
    font-size: 14px;
    color: #606266;
    height: 34px;
    line-height: 34px;
    border-radius: 20px;
    border: 1px solid #51CDCB;
    padding: 0 15px;
    margin-left: 10px;
    position: relative;
    .del{
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: #51CDCB;
        position: absolute;
        right: -5px;
        top: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
::v-deep{
    .el-select{
        width: 100%;
    }
    .anchorBL, .BMap_cpyCtrl{
        display: none;
    }
}
</style>