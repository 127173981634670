<template>
    <div class="intorduce_con">
        <el-form
            :model="formInfo"
            ref="ruleForm"
            label-width="100px"
        >
            <el-form-item label="企业介绍">
                <quill-editor
                    style="width: 65%"
                    ref="myQuillEditorOne"
                    :options="editorOptionOne"
                    v-model="formInfo.companyIntroduce"
                    @ready="ready($event)"
                />
            </el-form-item>
            <el-form-item>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="企业愿景" name="first">
                        <el-input
                            type="textarea"
                            :rows="4"
                            placeholder="请输入企业愿景"
                            :maxlength="200"
                            v-model="formInfo.comapnyVish"
                        ></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="企业使命" name="second">
                        <el-input
                            type="textarea"
                            :rows="4"
                            placeholder="请输入企业使命"
                            :maxlength="200"
                            v-model="formInfo.companyMission"
                        ></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="企业文化" name="third">
                        <el-input
                            type="textarea"
                            :rows="4"
                            placeholder="请输入企业文化"
                            :maxlength="200"
                            v-model="formInfo.companyCulture"
                        ></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="企业理念" name="fourth">
                        <el-input
                            type="textarea"
                            :rows="4"
                            placeholder="请输入企业理念"
                            :maxlength="200"
                            v-model="formInfo.companyIdea"
                        ></el-input>
                    </el-tab-pane>
                </el-tabs>
            </el-form-item>
            <el-form-item label="资质荣誉">
                <el-upload
                    :action="uploadUrl"
                    :file-list="fileList"
                    list-type="picture-card"
                    multiple
                    :on-remove="handleRemove"
                    :on-success="handleSuccess"
                >
                    <i slot="default" class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input  placeholder="请输入联系电话" v-model="formInfo.phone"></el-input>
            </el-form-item>
            <el-form-item label="企业地址">
                <el-input id="inputSelect" type="text" placeholder="请输入企业地址" v-model="formInfo.address" @change="theLocation"></el-input>
                <div
                    id="baidumap"
                    style="width: 65%; height: 400px; margin-top: 10px"
                    v-if="mapShow"
                ></div>
            </el-form-item>
            <el-form-item>
                <div class="d-flex justify-content-center" style="width:65%">
                    <el-button :loading="restLoad" @click="changeRest">重置</el-button>
                    <el-button class="save" type="primary" :loading="load" @click="save">保存</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { lineHeightStyle } from "@/utils/lineHeight.js";
    import { quillEditor, Quill } from "vue-quill-editor";
    import resizeImage from "quill-image-resize-module";
    import { ImageDrop } from "quill-image-drop-module";
    import quillConfig from "@/utils/quill-config.js";
    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/resizeImage ", resizeImage);
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    let ac;
    import { querybussinDesc,updataIntroduce,businDescrest } from '@/api/mobileWeb.js';

    export default {
        components:{
            quillEditor
        },
        data(){
            return {
                editorOptionOne:{
                    ...quillConfig,
                    placeholder:"请输入企业介绍"
                },
                formInfo:{
                    companyIntroduce: '',
                    comapnyVish: '',
                    companyMission: '',
                    companyCulture: '',
                    companyIdea: '',
                    phone: '',
                    address: '',
                    honorImg: ''
                },
                longitude:116.404,
                latitude: 39.915,
                map: {},
                local: {},
                load: false,
                restLoad: false,
                websiteId: '',
                mapShow: false,
                activeName: 'first',
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                fileList: [],
            }
        },
        methods:{
          ready() {
            Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
          },
            createdMap() {
                let navigationControl = new BMap.NavigationControl({
                    anchor: BMAP_ANCHOR_TOP_LEFT,
                    type: BMAP_NAVIGATION_CONTROL_LARGE,
                    enableGeolocation: true,
                });
                this.map = new BMap.Map("baidumap");
                let point = new BMap.Point(this.longitude, this.latitude);
                this.map.centerAndZoom(point, 18);
                this.map.addOverlay(new BMap.Marker(point));
                this.map.addControl(navigationControl);

                ac = new BMap.Autocomplete({
                    input: "inputSelect",
                    location: this.map,
                });
                ac.setInputValue(this.formInfo.address);
                ac.addEventListener("onconfirm", this.setValue);
            },
            setValue(e) {
                const {
                    item: { value },
                } = e;
                this.formInfo.address = `${value.city}${value.district}${value.business}`;
            },
            myFun() {
                if(this.local.getResults()){
                    let pp = this.local.getResults().getPoi(0).point;
                    this.map.centerAndZoom(pp, 18);
                    this.map.addOverlay(new BMap.Marker(pp));
                    this.latitude = pp.lat;
                    this.longitude = pp.lng;
                }
            },
            theLocation(val) {
                this.map.clearOverlays();
                this.local = new BMap.LocalSearch(this.map, {
                    onSearchComplete: this.myFun,
                });
                this.local.search(val);
            },
            handleRemove(file, fileList) {
                this.fileList = fileList;
                if(fileList.length){
                    this.formInfo.honorImg = (this.fileList.map(v => this.ossUrl + v.response?.data)).join(',');
                }else{
                    this.formInfo.honorImg = '';
                }
            },
            handleSuccess(response, file, fileList) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.fileList = fileList;
                this.formInfo.honorImg = (this.fileList.map(v => this.ossUrl + v.response?.data)).join(',');
            },
            getData(callback){
                let opts = {ignore: true};
                querybussinDesc(opts).then(res=>{
                    if(res.code == 200){
                        Object.keys(res.data).forEach((v,i)=>{
                            Object.keys(this.formInfo).forEach((a,b)=>{
                                if(a == v && v){
                                    this.formInfo[a] = res.data[v];
                                }
                            })
                        });
                        if(res.data.honorImg){
                            this.fileList = [];
                            let arr = res.data.honorImg.split(',');
                            arr.forEach((v,i)=>{
                                this.fileList.push({
                                    url: v,
                                    uid: i + 1,
                                    response:{
                                        data: v.slice(this.findStr(v,'/',2) + 1)
                                    }
                                });
                            }) 
                        }else{
                            this.fileList = [];
                        }
                        this.websiteId = res.data.websiteId;
                        this.longitude = res.data.longitude || 116.404;
                        this.latitude = res.data.latitude || 39.915;

                        callback && callback();
                    }else{
                        this.formInfo = {
                            companyIntroduce: '',
                            comapnyVish: '',
                            companyMission: '',
                            companyCulture: '',
                            companyIdea: '',
                            phone: '',
                            address: '',
                            honorImg: ''
                        };
                        this.fileList = [];
                        this.websiteId = '';
                        this.longitude = 116.404;
                        this.latitude = 39.915;

                        this.$nextTick(()=>{
                            this.restMap();
                        })
                    }
                })
            },
            changeRest(){
                if(!this.websiteId){
                    this.formInfo = {
                        companyIntroduce: '',
                        comapnyVish: '',
                        companyMission: '',
                        companyCulture: '',
                        companyIdea: '',
                        phone: '',
                        address: '',
                        honorImg: ''
                    };
                    this.fileList = [];
                    this.websiteId = '';
                    this.longitude = 116.404;
                    this.latitude = 39.915;

                    this.$nextTick(()=>{
                        this.restMap();
                    });
                    return;
                };
                let opts = {
                    websiteId: this.websiteId
                };
                this.restLoad = true;
                try{
                    businDescrest(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.restLoad = false;
                            this.getData(()=>{
                                this.$nextTick(()=>{
                                    this.createdMap();
                                })
                            });
                        }else{
                            this.$message.success(res.message);
                            this.restLoad = false;
                        }
                    }).catch(err=>{
                        this.restLoad = false;
                    })
                }catch(err){
                    this.restLoad = false;
                }
            },
            save(){
                let reg1 = /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/;
                let reg2 = /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/;
                if(this.formInfo.phone){
                    if (!reg1.test(this.formInfo.phone) && !reg2.test(this.formInfo.phone)) {
                        this.$message.error('请输入正确的联系电话');
                        return;
                    };
                };
                if(!this.formInfo.companyIntroduce){
                    this.formInfo.comapnyVish = '';
                    this.formInfo.companyMission = '';
                    this.formInfo.companyCulture = '';
                    this.formInfo.companyIdea = '';
                };
                let opts = {
                    ...this.formInfo,
                    websiteId: this.websiteId,
                    longitude: this.longitude,
                    latitude: this.latitude,
                };
                this.load = true;
                try{
                    updataIntroduce(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.load = false;
                            this.getData(()=>{
                                this.$nextTick(()=>{
                                    this.createdMap();
                                })
                            });
                        }else{
                            this.$message.success(res.message);
                            this.load = false;
                        }
                    }).catch(err=>{
                        this.load = false;
                    })
                }catch(err){
                    this.load = false;
                }
            },
            restMap(){
                this.mapShow = true;
                this.$nextTick(()=>{
                    this.createdMap();
                })
            },
            restDefaultMap(){
                this.mapShow = false;
            },
            findStr(str,cha,num){
				let x=str.indexOf(cha);
				for(let i=0;i<num;i++){
					x=str.indexOf(cha,x+1);
				}
				return x;
			},
        },
        beforeDestroy() {
            ac.removeEventListener("onconfirm", this.setValue);
        },
        mounted(){
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
    .intorduce_con{
        width: 100%;
        padding-top: 24px;
    }
    ::v-deep{
        .el-input__inner{
            width: 65%;
        }
        .el-textarea__inner{
            width: 65%;
        }
        .anchorBL, .BMap_cpyCtrl{
            display: none;
        }
        .el-tabs__header{
            border: none;
        }
        .el-tabs__item{
            background: #fff;
        }
        .el-tabs__nav-scroll{
            background: #fff;
        }
        .disabled .el-upload--picture-card, .disabled .el-upload--text {
            display: none;
        }
    }
</style>
