<template>
    <div class="servicecasesCon">
        <div class="header_con d-flex">
            <el-button type="primary" icon="el-icon-plus" @click="add">添加案例</el-button>
        </div>
        <common-table
            :tableData="tableData"
            :loading="loading"
            :total="total"
            @handleCurrentChange="CurrentChange"
            @handleSizeChange="SizeChange"
            :currentPage="currentPage"
        >
            <template slot="table">
                <el-table-column align="center" label="编号" prop="casesId" />
                <el-table-column align="center" label="案例名称" prop="casesName" />
                <el-table-column align="center" label="主图">
                    <template slot-scope="scope">
                        <el-image
                            fit="cover"
                            v-if="scope.row.casesImg"
                            style="width: 100px; height: 80px;"
                            :src="scope.row.casesImg"
                        ></el-image>
                        <p
                            v-else
                            style="width: 100px; height: 80px;line-height: 80px;margin-bottom:-10px"
                        >
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="排序" prop="sort" />
                <el-table-column align="center" label="显示状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="(val) => switchStatus(val,scope.row.casesId)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间" prop="createTime" />
                <el-table-column label="操作" width="140" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
                        <el-button class="delColor" size="mini" type="text" @click="del(scope.row.casesId)">删除</el-button>
                    </template>
                </el-table-column>
            </template>
        </common-table>
        
        <add-pop ref="addPop" @success="success"/>
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import addPop from './add';
    import { querySercase,delSercase,editSercase } from '@/api/mobileWeb.js';
    import { str2time } from '@/utils/util.js';

    export default {
        components: {
            commonTable,
            addPop
        },
        data() {
            return {
                tableData: [],
                loading: false,
                total: 0,
                pageNum:1,
                pageSize:10,
                currentPage: 1,
            };
        },
        methods: {
            CurrentChange(num){
                this.pageNum = num;
                this.getData();
            },
            SizeChange(num){
                this.pageSize = num;
                this.pageNum = 1;
                this.currentPage = 1;
                this.getData();
            },
            success(){
                this.getData();
            },
            add(){
                this.$refs.addPop.init('add');
            },
            edit(item){
                let data = JSON.parse(JSON.stringify(item))
                this.$refs.addPop.init('edit',data);
            },
            switchStatus(val,id){
                let status = val ? 0 : 1;
                let opts = {
                    status,
                    casesId: id
                };
                editSercase(opts).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.getData();
                    }else{
                        this.$message.error(res.message);
                    }
                }).catch(err=>{
                })
            },
            del(id){
                let opts = {
                    casesId: id
                };
                delSercase(opts).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.getData();
                    }else{
                        this.$message.error(res.message);
                    }
                }).catch(err=>{
                })
            },
            getData(){
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                };
                this.loading = true;
                querySercase(opts).then(res=>{
                    if(res.code == 200){
                        let { pageInfo } = res.data;
                        if(pageInfo.list.length){
                            pageInfo.list.forEach(v=>{
                                v.createTime = str2time(v.createTime);
                                v.status = v.status == 0 ? true : false;
                            })
                            this.tableData = pageInfo.list;
                        };
                        this.total = pageInfo.total;
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                }).catch(err=>{
                    this.loading = false;
                })
            }
        },
        mounted() {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
    .servicecasesCon{
        width: 100%;
        padding: 24px;
    }
</style>