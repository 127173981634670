<template>
    <el-dialog 
        :modal-append-to-body="false" 
        :title="type == 'add' ? '添加轮播图' : '编辑轮播图'" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-form
                    :model="formInfo"
                    ref="ruleForm"
                    label-width="100px"
                    class="form_con"
                    :rules="rules"
                >
                <el-form-item label="图片" prop="url">
                    <el-upload
                        :action="uploadUrl"
                        :class="{ disabled: fileList.length > 0 }"
                        :file-list="fileList"
                        list-type="picture-card"
                        :limit="1"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input placeholder="排序越大越靠前" v-model="formInfo.sort"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { addBanner,editBanner } from '@/api/mobileWeb.js';

    export default{
        data(){
            return {
                dialogVisible: false,
                type:'add',
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                formInfo:{
                    url: '',
                    sort: '',
                },
                fileList:[],
                load: false,
                imgId: '',
                rules: {
                    url: [{ required: true, message: "请上传图片！", trigger: "blur" }],
                },
            }
        },
        methods:{
            init(type,data){
                this.type = type;
                if(type == 'edit'){
                    this.imgId = data.imgId;
                    this.setDate(data);
                };
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                this.fileList = [];
                this.formInfo.url = '';
            },
            handleSuccess(response, file, fileList) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.fileList = fileList;
                this.formInfo.url = this.ossUrl +  file.response.data;
            },
            setDate(data){
                this.formInfo = {
                    url: data.url,
                    sort: data.sort,
                };
                this.fileList = [{
                    url: data.url,
                    uid: 1,
                    response:{
                        data: data.url.slice(this.findStr(data.url,'/',2) + 1)
                    }
                }]; 
            },
            close(){
                this.formInfo = {
                    url: '',
                    sort: '',
                };
                this.fileList = [];
                this.dialogVisible = false;
            },
            save(){
                if(!this.formInfo.url){
                    this.$message.error('请上传图片！');
                    return;
                };
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                    ...this.formInfo
                };
                if(this.type == 'edit'){
                    opts.imgId = this.imgId;
                };
                this.load = true;
                if(this.type == 'add'){
                    try{
                        addBanner(opts).then(res=>{
                            if(res.code == 200){
                                this.$message.success(res.message);
                                this.$emit('success');
                                this.load = false;
                                this.close();
                            }else{
                                this.$message.success(res.message);
                                this.load = false;
                            }
                        })
                    }catch(err){
                        this.load = false;
                    }
                }else{
                    try{
                        editBanner(opts).then(res=>{
                            if(res.code == 200){
                                this.$message.success(res.message);
                                this.$emit('success');
                                this.load = false;
                                this.close();
                            }else{
                                this.$message.success(res.message);
                                this.load = false;
                            }
                        })
                    }catch(err){
                        this.load = false;
                    }
                }
            },
            findStr(str,cha,num){
				let x=str.indexOf(cha);
				for(let i=0;i<num;i++){
					x=str.indexOf(cha,x+1);
				}
				return x;
			},
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
::v-deep{
    .el-form--inline .el-form-item__content{
        width: 500px;
    }
    .disabled .el-upload--picture-card, .disabled .el-upload--text {
        display: none;
    }
}
</style>