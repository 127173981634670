<template>
    <el-dialog 
        :modal-append-to-body="false" 
        :title="type == 'add' ? '添加视频' : '编辑视频'" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-form
                    :model="formInfo"
                    ref="vruleForm"
                    label-width="100px"
                    class="form_con"
                    :rules="rules"
                >
                <el-form-item label="视频标题" prop="describes">
                    <el-input placeholder="请输入视频标题" v-model="formInfo.describes"></el-input>
                </el-form-item>
                <el-form-item label="封面图" prop="cover">
                    <el-upload
                        :action="uploadUrl"
                        :class="{ disabled: fileList.length > 0 }"
                        :file-list="fileList"
                        list-type="picture-card"
                        :limit="1"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input placeholder="排序越大越靠前" v-model="formInfo.sort"></el-input>
                </el-form-item>
                <el-form-item label="视频" prop="url">
                    <el-radio-group v-model="radio" class="mar_b" v-if="!formInfo.url">
                        <el-radio label="本地视频"></el-radio>
                        <el-radio label="视频链接"></el-radio>
                    </el-radio-group>
                    <div class="d-flex" v-loading="upLoading">
                        <div class="videolist d-flex" v-if="videoList.length">
                            <div class="video_item" v-for="(item,index) in videoList" :key="index">
                                <video controls>
                                    <source :src="ossUrl + item.response.data">
                                </video>
                                <div class="del d-flex" @click="videoRemove(index)">
                                    <i class="el-icon-delete"></i>
                                </div>
                            </div>
                        </div>
                        <template v-if="radio == '本地视频'">
                            <el-upload
                                :action="uploadUrl"
                                :class="{ disabled: videoList.length > 0 }"
                                :file-list="videoList"
                                list-type="picture-card"
                                :limit="1"
                                :on-success="videoSuccess"
                                :before-upload="beforeUploadVideo"
                                accept=".mp4"
                                :show-file-list="false"
                                :on-progress="videoProgress"
                            >
                                <i slot="default" class="el-icon-plus"></i>
                            </el-upload>
                        </template>
                        <template v-else>
                            <el-input placeholder="请输入视频链接" v-model="formInfo.url"></el-input>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { addVideo } from '@/api/mobileWeb.js';

    export default{
        data(){
            return {
                dialogVisible: false,
                type:'add',
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                fileList: [],
                videoList: [],
                formInfo:{
                    cover: '',
                    url: '',
                    describes: '',
                    sort: '',
                },
                radio: '本地视频',
                load: false,
                videoId: '',
                rules: {
                    describes: [{ required: true, message: "请输入视频标题", trigger: "blur" }],
                    cover:[{ required: true, message: "请上传封面图！", trigger: "blur" }],
                    url:[{ required: true, message: "请上传视频！", trigger: "blur" }],
                },
                upLoading: false,
            }
        },
        methods:{
            init(type,data){
                this.type = type;
                if(type == 'edit'){
                    this.filterData(data);
                };
                this.dialogVisible = true;
            },
            close(){
                this.formInfo = {
                    cover: '',
                    url: '',
                    describes: '',
                    sort: '',
                };
                this.fileList = [];
                this.videoList = [];
                this.radio = '本地视频';
                this.load = false;
                this.$refs.vruleForm.resetFields();
                this.dialogVisible = false;
            },
            handleRemove(file, fileList) {
                this.fileList = [];
                this.formInfo.cover = '';
            },
            handleSuccess(response, file, fileList) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.fileList = fileList;
                this.formInfo.cover = this.ossUrl +  file.response.data;
            },
            videoRemove(idx) {
                this.videoList.splice(idx, 1);
                this.formInfo.url = '';
            },
            videoSuccess(response, file, fileList){
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.videoList = fileList;
                this.formInfo.url = this.ossUrl +  file.response.data;
            },
            beforeUploadVideo(file) {
                const isLt2M = file.size / 1024 / 1024 < 30;
                if (!isLt2M) {
                    this.$message.error("上传视频大小不能超过 30MB!");
                }
                return isLt2M;
            },
            videoProgress(event, file, fileList){
                this.upLoading = true;
                if(event.percent == 100){
                    this.upLoading = false;
                }
            },
            filterData(data){
                let { cover,url,describes,sort,videoId } = data;
                this.videoId = videoId;
                this.formInfo = {
                    cover,
                    url,
                    describes,
                    sort,
                };
                this.fileList = [{
                    url: cover,
                    uid: 1,
                    response:{
                        data: cover.slice(this.findStr(cover,'/',2) + 1)
                    }
                }];
                this.videoList = [{
                    url: url,
                    uid: 1,
                    response:{
                        data: url.slice(this.findStr(url,'/',2) + 1)
                    }
                }] 
            },
            findStr(str,cha,num){
                let x=str.indexOf(cha);
                for(let i=0;i<num;i++){
                    x=str.indexOf(cha,x+1);
                }
                return x;
            },            
            save(){
                this.$refs.vruleForm.validate((valid)=>{
                    if(valid){
                        if(!this.formInfo.cover){
                            this.$message.error('请上传封面图！');
                            return;
                        };
                        if(!this.formInfo.url){
                            this.$message.error('请上传视频！');
                            return;
                        };
                        let opts = {
                            companyId: localStorage.getItem('companyId'),
                            ...this.formInfo,
                        };
                        if(this.type == 'edit'){
                            opts.videoId = this.videoId;
                        };
                        if(this.radio == '本地视频'){
                            opts.videoFrom = 1;
                        }else{
                            opts.videoFrom = 2;
                        };
                        this.load = true;
                        try{
                            addVideo(opts).then(res=>{
                                if(res.code == 200){
                                    this.$message.success(res.message);
                                    this.$emit('success');
                                    this.load = false;
                                    this.close();
                                }else{
                                    this.$message.success(res.message);
                                    this.load = false;
                                }
                            }).catch(err=>{
                                this.load = false;
                            })
                        }catch(err){
                            this.load = false;
                        }
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
.mar_b{
    margin-bottom: 10px;
}
.videolist{
    flex-wrap: wrap;
    .video_item{
        width: 148px;
        height: 148px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;

        video{
            width: 148px;
            height: 148px;
            object-fit: cover;

        }
        .del{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i{
                color: #51CBCD;
            }
        }
    }
}
::v-deep{
    .el-form--inline .el-form-item__content{
        width: 500px;
    }
    .disabled .el-upload--picture-card, .disabled .el-upload--text {
        display: none;
    }
}
</style>