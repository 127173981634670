<template>
    <el-dialog 
        :modal-append-to-body="false" 
        :title="type == 'add' ? '发布企业动态' : '编辑企业动态'" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body" v-loading="loading">
            <el-form
                    :model="formInfo"
                    ref="dyruleForm"
                    label-width="100px"
                    class="form_con"
                    :rules="rules"
                >
                <el-form-item label="动态标题" prop="name">
                    <el-input  placeholder="请输入动态标题" v-model="formInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="封面图" prop="img">
                    <el-upload
                        :action="uploadUrl"
                        :class="{ disabled: fileList.length > 0 }"
                        :file-list="fileList"
                        list-type="picture-card"
                        :limit="1"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <!-- <el-form-item label="排序">
                    <el-input  placeholder="排序越大越靠前"></el-input>
                </el-form-item> -->
                <el-form-item label="内容" prop="content">
                    <quill-editor
                        style="width: 100%"
                        ref="myQuillEditorTwo"
                        :options="editorOptionTwo"
                        v-model="formInfo.content"
                    />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { quillEditor, Quill } from "vue-quill-editor";
    import resizeImage from "quill-image-resize-module";
    import { ImageDrop } from "quill-image-drop-module";
    import quillConfig from "@/utils/quill-config.js";
    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/resizeImage ", resizeImage);
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import { addDynamic,dynamicDetail,updataDynamic } from '@/api/mobileWeb.js';

    export default{
        components:{
            quillEditor
        },
        data(){
            return {
                editorOptionTwo:{
                    ...quillConfig,
                    placeholder:"请输入内容"
                },
                dialogVisible: false,
                type:'add',
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                fileList: [],
                formInfo:{
                    img: '',
                    name: '',
                    content: '',
                },
                load: false,
                dynamicId: '',
                loading: false,
                rules: {
                    name: [{ required: true, message: "请输入动态标题！", trigger: "blur" }],
                    content: [{ required: true, message: "请输入内容！", trigger: "blur" }],
                    img: [{ required: true, message: "请上传封面图！", trigger: "blur" }],
                },
            }
        },
        methods:{
            init(type,dynamicId){
                this.type = type;
                if(type == 'edit'){
                    this.dynamicId = dynamicId;
                    this.getDetail(dynamicId);
                };
                this.dialogVisible = true;
            },
            close(){
                this.fileList = [];
                this.formInfo = {
                    img: '',
                    name: '',
                    content: '',
                };
                this.load = false;
                this.$refs.dyruleForm.resetFields();
                this.dialogVisible = false;
            },
            handleRemove(file, fileList) {
                this.fileList = [];
                this.formInfo.img = '';
            },
            handleSuccess(response, file, fileList) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.fileList = fileList;
                this.formInfo.img = this.ossUrl +  file.response.data;
            },
            getDetail(dynamicId){
                this.loading = true;
                let opts = { dynamicId };
                dynamicDetail(opts).then(res=>{
                    if(res.code == 200){
                        let { img,name,content } = res.data;
                        this.formInfo = {
                            img,
                            name,
                            content,
                        };
                        this.fileList = [{
                            url: img,
                            uid: 1,
                            response:{
                                data: img.slice(this.findStr(img,'/',2) + 1)
                            }
                        }];
                        this.loading = false;
                    }else{
                        this.loading = false; 
                    }
                }).catch(err=>{
                    this.loading = false; 
                })
            },
            findStr(str,cha,num){
                let x=str.indexOf(cha);
                for(let i=0;i<num;i++){
                    x=str.indexOf(cha,x+1);
                }
                return x;
            }, 
            save(){
                this.$refs.dyruleForm.validate((valid) => {
                    if(valid){
                        if(!this.formInfo.img){
                            this.$message.error('请选择封面图！');
                            return;
                        };
                        let opts = {
                            companyId: localStorage.getItem('companyId'),
                            ...this.formInfo,
                        };
                        if(this.type == 'edit'){
                            opts.dynamicId = this.dynamicId;
                        };
                        this.load = true;
                        if(this.type == 'add'){
                            try{
                                addDynamic(opts).then(res=>{
                                    if(res.code == 200){
                                        this.$message.success(res.message);
                                        this.$emit('success');
                                        this.load = false;
                                        this.close();
                                    }else{
                                        this.$message.success(res.message);
                                        this.load = false;
                                    }
                                }).catch(err=>{
                                    this.load = false;
                                })
                            }catch(err){
                                this.load = false;
                            }
                        }else{
                            try{
                                updataDynamic(opts).then(res=>{
                                    if(res.code == 200){
                                        this.$message.success(res.message);
                                        this.$emit('success');
                                        this.load = false;
                                        this.close();
                                    }else{
                                        this.$message.success(res.message);
                                        this.load = false;
                                    }
                                }).catch(err=>{
                                    this.load = false;
                                })
                            }catch(err){
                                this.load = false;
                            }
                        }
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
::v-deep{
    .el-form--inline .el-form-item__content{
        width: 500px;
    }
    .disabled .el-upload--picture-card, .disabled .el-upload--text {
        display: none;
    }
}
</style>