<template>
    <div class="bannercon">
        <div class="header_con d-flex">
            <el-button type="primary" @click="add">发布动态</el-button>
        </div>
        <common-table
            :tableData="tableData"
            :loading="loading"
            :total="total"
            @handleCurrentChange="CurrentChange"
            @handleSizeChange="SizeChange"
            :currentPage="currentPage"
        >
           <template slot="table">
                <el-table-column prop="name" align="center" label="动态标题" />
                <el-table-column align="center" label="封面图">
                    <template slot-scope="scope">
                        <el-image
                            fit="cover"
                            v-if="scope.row.img"
                            style="width: 100px; height: 80px;"
                            :src="scope.row.img"
                        ></el-image>
                        <p
                            v-else
                            style="width: 100px; height: 80px;line-height: 80px;margin-bottom:-10px"
                        >
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="sort" align="center" label="排序" /> -->
                <el-table-column label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="edit(scope.row.dynamicId)">编辑</el-button>
                        <el-button class="delColor" size="mini" type="text" @click="del(scope.row.dynamicId)">删除</el-button>
                    </template>
                </el-table-column>
           </template>
        </common-table>

        <add-pop ref="addPop" @success="success" />
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import addPop from './add';
    import { queryDynamic,delDynamic } from '@/api/mobileWeb.js';

    export default {
        components:{
            commonTable,
            addPop
        },
        data(){
            return {
                tableData:[],
                total:0,
                pageNum:1,
                pageSize:10,
                loading:false,
                currentPage: 1,
            }
        },
        methods:{
            add(){
                this.$refs.addPop.init('add');
            },
            edit(dynamicId){
                this.$refs.addPop.init('edit',dynamicId);
            },
            CurrentChange(num){
                this.pageNum = num;
                this.getList();
            },
            SizeChange(num){
                this.pageSize = num;
                this.pageNum = 1;
                this.currentPage = 1;
                this.getList();
            },
            del(dynamicId){
                let opts = {dynamicId};
                delDynamic(opts).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.getList();
                    }else{
                        this.$message.success(res.message);
                    }
                });
            },
            getList(){
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                };
                this.loading = true;
                try{
                    queryDynamic(opts).then(res=>{
                        if(res.code == 200){
                            let { pageInfo } = res.data;
                            if(pageInfo.list.length){
                                this.tableData = pageInfo.list;
                            };
                            this.total = pageInfo.total;
                            this.loading = false;
                        }else{
                            this.tableData = [];
                            this.loading = false;
                        }
                    }).catch(err=>{
                        this.loading = false;
                    })
                }catch(err){
                    this.loading = false;
                }
            },
            success(){
               this.getList(); 
            },
        },
        mounted() {
            this.getList();
        },
    }
</script>

<style lang="scss" scoped>
    .bannercon{
        width: 100%;
        padding: 24px;
        .header_con{

        }
    }
</style>