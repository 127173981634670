import httpUtil from "@/utils/httpUtil";

//查询企业
export const querybussin = async params => httpUtil.post("/api/assPc/backFunction/selectWebsiteInfo", params);

//查询企业介绍
export const querybussinDesc = async params => httpUtil.post("/api/assPc/backFunction/selectWebsiteInfoByCId", params);

//查询banner
export const queryBanner = async params => httpUtil.post("/api/assPc/backFunction/queryPageRotationImgPage", params);

//添加banner
export const addBanner = async params => httpUtil.post("/api/assPc/backFunction/addPublicRotationImg", params);

//修改banner
export const editBanner = async params => httpUtil.post("/api/assPc/backFunction/updateRotationImgPage", params);

//删除banner
export const delBanner = async params => httpUtil.post("/api/assPc/backFunction/delRotationImgPage", params);

// 添加修改企业介绍
export const updataIntroduce = async params => httpUtil.post("/api/assPc/backFunction/addWebsiteInfo", params);

// 分页查询企业视频
export const queryVideo = async params => httpUtil.post("/api/assPc/backFunction/queryCompanyVideoPage", params);

//添加video
export const addVideo = async params => httpUtil.post("/api/assPc/backFunction/insertCompanyVideo", params);

//删除video
export const delVideo = async params => httpUtil.post("/api/assPc/backFunction/delCompanyVideo", params);

// 查询企业动态数据
export const queryDynamic = async params => httpUtil.post("/api/assPc/backFunction/queryCompanyDynamicPage", params);

// 添加企业动态详情数据
export const addDynamic = async params => httpUtil.post("/api/assPc/backFunction/addCompanyDynamic", params);

// 查询企业动态详情数据
export const dynamicDetail = async params => httpUtil.post("/api/assPc/backFunction/queryCompanyDynamicById", params);

// 修改企业动态详情数据
export const updataDynamic = async params => httpUtil.post("/api/assPc/backFunction/updateCompanyDynamicById", params);

// 删除企业动态详情数据
export const delDynamic = async params => httpUtil.post("/api/assPc/backFunction/delCompanyDynamicById", params);

// 查询公司招聘
export const queryRecruirt = async params => httpUtil.post("/api/assPc/backFunction/selectCompanyRecruitPage", params);

// 查询所属行业列表
export const queryHy = async params => httpUtil.post("/api/assPc/backFunction/queryPositionIndustry", params);

// 添加公司招聘
export const addRecruirt = async params => httpUtil.post("/api/assPc/backFunction/addCompanyRecruit", params);

// 删除公司招聘
export const delRecruirt = async params => httpUtil.post("/api/assPc/backFunction/delCompanyRecruit", params);

//hrtel
export const addHrtel = async params => httpUtil.post("/api/assPc/backFunction/addHrPhone", params);

//查询企业历程
export const companyHis = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/queryAllCompanyCourse", params);

//企业历程新增
export const companyAdd = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/addCompanyCourseList", params);

//企业历程编辑
export const companyEdit = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/updateCompanyCourse", params);

//企业历程删除
export const companyDel = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/delCompanyCourse", params);

//产品介绍查询
export const queryProduct = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/queryCompanyWebsiteProductPage", params);

//产品介绍编辑
export const editProduct = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/updateCompanyWebsiteProduct", params);

//产品介绍新增
export const addProduct = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/addCompanyWebsiteProduct", params);

//产品介绍删除
export const delProduct = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/delCompanyWebsiteProduct", params);

//服务案例查询
export const querySercase = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/queryCompanyServiceCasesPage", params);

//服务案例编辑
export const editSercase = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/updateCompanyServiceCases", params);

//服务案例新增
export const addSercase = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/addCompanyServiceCases", params);

//服务案例删除
export const delSercase = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/delCompanyServiceCases", params);

//合作伙伴查询
export const queryPartner = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/queryCpmpanyPartnerAll", params);

//合作伙伴编辑
export const editPartner = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/updateCpmpanyPartner", params);

//合作伙伴新增
export const addPartner = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/addCpmpanyPartner", params);

//合作伙伴删除
export const delPartner = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/delCpmpanyPartner", params);

//我们的服务查询
export const queryMyser = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/queryCompanyMyServiceAll", params);

//我们的服务查询-分页
export const queryPageMyser = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/queryCompanyMyServicePage", params);

//我们的服务编辑
export const editMyser = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/updateCompanyMyService", params);

//我们的服务头像
export const editMyserImg = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/updateCompanyMyServiceImg", params);  

//我们的服务新增
export const addMyser = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/addCompanyMyService", params);

//我们的服务删除
export const delMyser = async params => httpUtil.post("/api/crmPc/companyWebsiteBase/delCompanyMyService", params);

//重置企业介绍
export const businDescrest = async params => httpUtil.post("/api/assPc/backFunction/delWebsiteInfo", params);