<template>
    <div class="weserviceCon">
        <div class="header_con d-flex">
            <el-button type="primary" icon="el-icon-plus" @click="add">添加服务</el-button>
        </div>
        <div class="card_con d-flex" v-loading="loading">
            <el-card class="card"
                v-for="(item,index) in cardList"
                :key="index"
            >
                <div class="card_body d-flex">
                    <div class="options d-flex">
                        <i class="el-icon-edit-outline" @click="edit(index,item.myServiceId)"></i>
                        <i class="el-icon-delete" @click="del(index,item.myServiceId)"></i>
                    </div>
                    <div class="avatar">
                        <el-upload
                            :disabled="!item.isEdit"
                            :action="uploadUrl"
                            :file-list="item.fileList"
                            :limit="1"
                            :class="{ disabled: item.fileList.length > 0 }"
                            list-type="picture-card"
                            :on-remove="(file, fileList) => handleRemove(file, fileList,index,item.myServiceId)"
                            :on-success="(response, file, fileList) => handleSuccess(response, file, fileList,index,item.myServiceId)"
                        >
                            <div class="addbtn d-flex">
                                <i slot="default" class="el-icon-plus"></i>
                            </div>
                        </el-upload>
                    </div>
                    <el-input 
                        class="mt18" 
                        placeholder="请输入标题" 
                        v-model="item.title" 
                        :maxlength="10"
                        :disabled="!item.isEdit"
                    ></el-input>
                    <!-- @blur="(val) => changeTitle(val,index,item.myServiceId)"  -->
                    <el-input 
                        class="mt18"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入简介"
                        v-model="item.seriveIntroduce"
                        :maxlength="30"
                        :disabled="!item.isEdit"
                    ></el-input>
                    <!-- @blur="(val) => changeDesc(val,index,item.myServiceId)" -->
                    <template v-if="item.isEdit">
                        <div class="mt20 d-flex justify-content-center">
                            <el-button @click="cancel(index,item.myServiceId)">取消</el-button>
                            <el-button type="primary" :loading="item.load" @click="save(index,item.myServiceId)">保存</el-button>
                        </div>
                    </template>
                </div>
            </el-card>
        </div>
        <div class="d-flex justify-content-end mt20">
            <el-pagination
                background
                @size-change="SizeChange"
                @current-change="CurrentChange"
                :current-page="currentPage"
                :page-sizes="[8, 16, 24, 32, 40]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            />
        </div>
    </div>
</template>

<script>
    import { queryMyser,delMyser,addMyser,editMyser,queryPageMyser,editMyserImg } from '@/api/mobileWeb.js';

    export default {
        data() {
            return {
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                cardList:[
                    {fileList: []}
                ],
                loading: false,
                currentPage: 1,
                total:0,
                pageNum:1,
                pageSize:8,
            };
        },
        methods: {
            add(){
                this.cardList.push({
                    fileList: [],
                    img: '',
                    title: '',
                    seriveIntroduce: '',
                    isEdit: true,
                    load: false,
                })
            },
            handleRemove(file, fileList,index,myServiceId) {
                this.cardList[index].fileList = [];
                this.cardList[index].img = '';
                // this.undataSerimg(myServiceId,index);
            },
            handleSuccess(response, file, fileList,index,myServiceId) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.cardList[index].fileList = fileList;
                this.cardList[index].img = this.ossUrl +  file.response.data;
                // this.updataSer(myServiceId,index);
            },
            changeTitle(val,index,myServiceId){
                if(this.cardList[index].title){
                    this.updataSer(myServiceId,index)
                }
            },
            changeDesc(val,index,myServiceId){
                if(this.cardList[index].seriveIntroduce){
                    this.updataSer(myServiceId,index)
                }
            },
            undataSerimg(myServiceId,index){
                if(myServiceId){
                    let item = this.cardList.find(v => v.myServiceId == myServiceId);
                    let opts = {
                        companyId: localStorage.getItem('companyId'),
                        myServiceId: item.myServiceId,
                        img: item.img,
                    };
                    editMyserImg(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.getData();
                        }else{
                            this.$message.error(res.message);
                        }
                    }).catch(err=>{
                    })
                }
            },
            updataSer(myServiceId,index){
                if(myServiceId){
                    let item = this.cardList.find(v => v.myServiceId == myServiceId);
                    let opts = {
                        companyId: localStorage.getItem('companyId'),
                        myServiceId: item.myServiceId,
                        img: item.img,
                        title: item.title,
                        seriveIntroduce: item.seriveIntroduce
                    };
                    this.cardList[index].load = true;
                    editMyser(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.getData();
                        }else{
                            this.$message.error(res.message);
                        };
                        this.cardList[index].load = false;
                    }).catch(err=>{
                        this.cardList[index].load = false;
                    })
                }else{
                    let opts = {
                        companyId: localStorage.getItem('companyId'),
                        img: this.cardList[index].img,
                        title: this.cardList[index].title,
                        seriveIntroduce: this.cardList[index].seriveIntroduce
                    };
                    this.cardList[index].load = true;
                    addMyser(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.getData();
                        }else{
                            this.$message.error(res.message);
                        };
                        this.cardList[index].load = false;
                    }).catch(err=>{
                        this.cardList[index].load = false;
                    })
                }
            },
            del(index,id){
                if(id){
                    let opts = {
                        myServiceId: id
                    };
                    delMyser(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.getData();
                        }else{
                            this.$message.error(res.message);
                        }
                    }).catch(err=>{
                    })
                }else{
                    this.cardList.splice(index,1)
                }
            },
            edit(index,id){
                if(id){
                    let idx = this.cardList.findIndex(v => v.myServiceId == id);
                    this.cardList[idx].isEdit = !this.cardList[idx].isEdit;
                }
            },
            //当前页变更
            CurrentChange(num){
                this.pageNum = num;
                this.getData();
            },
            SizeChange(num){
                this.pageSize = num;
                this.pageNum = 1;
                this.currentPage = 1;
                this.getData();
            },
            cancel(index,myServiceId){
                if(myServiceId){
                    this.cardList[index].isEdit = !this.cardList[index].isEdit;
                }else{
                    this.cardList.splice(index,1);
                }
            },
            save(index,myServiceId){
                if(myServiceId){
                    let item = this.cardList.find(v => v.myServiceId == myServiceId);
                    if(!item.img || !item.title || !item.seriveIntroduce){
                        this.$message.error('请完善资料！');
                        return;
                    };
                }else{
                    let item = this.cardList[index];
                    if(!item.img || !item.title || !item.seriveIntroduce){
                        this.$message.error('请完善资料！');
                        return;
                    };
                }
                this.updataSer(myServiceId,index);
            },
            getData(){
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                };
                this.loading = true;
                queryPageMyser(opts).then(res=>{
                    if(res.code == 200){
                        let { list } = res.data.pageInfo;
                        if(list.length){
                            list.forEach((v,i)=>{
                                v.isEdit = false;
                                v.load = false;
                                if(v.img){
                                    v.fileList = [{
                                        url: v.img,
                                        uid: i + 1,
                                        response:{
                                            data: v.img ? v.img.slice(this.findStr(v.img,'/',2) + 1) : ''
                                        }
                                    }];
                                }else{
                                    v.fileList = [];
                                }
                            });
                            this.cardList = list;
                        };
                        this.total = res.data.pageInfo.total;
                        this.loading = false;
                    }else{
                        this.cardList = [{
                            fileList: [],
                            img: '',
                            title: '',
                            seriveIntroduce: '',
                            isEdit: true,
                            load: false,
                        }];
                        this.loading = false;
                    }
                }).catch(err=>{
                    this.loading = false;
                })
            },
            findStr(str,cha,num){
				let x=str.indexOf(cha);
				for(let i=0;i<num;i++){
					x=str.indexOf(cha,x+1);
				}
				return x;
			},
        },
        mounted() {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
    .weserviceCon{
        width: 100%;
        padding: 24px;
        .card_con{
            padding: 24px 0 0 0;
            flex-wrap: wrap;
        }
        .card{
            width: 20%;
            height: max-content;
            margin-bottom: 24px;
            margin-right: 24px;
            .card_body{
                flex-direction: column;
                align-items: center;
                position: relative;
                .avatar{
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    .addbtn{
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .options{
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    i{
                        color: rgb(81, 203, 205);
                        cursor: pointer;
                        &:first-child{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    ::v-deep{
        .el-upload--picture-card{
            width: 64px;
            height: 64px;
            border-radius: 50%;
        }
        .el-upload-list__item{
            width: 64px;
            height: 64px;
            border-radius: 50%;
        }
        .disabled .el-upload--picture-card, .disabled .el-upload--text {
            display: none;
        }
        .el-upload-list__item{
            transition:none !important;
            -webkit-transition:nonne !important;
       }
        .el-upload-list__item-name{
            transition:none !important;
            -webkit-transition:nonne !important;
        }
        .el-upload-list__item.is-success .el-upload-list__item-status-label{
            display: none !important;
        }

        .el-input.is-disabled .el-input__inner {
            background-color: #fff;
            border-color: #E4E7ED;
            cursor: not-allowed;
        }

        .el-textarea.is-disabled .el-textarea__inner{
            background-color: #fff;
            border-color: #E4E7ED;
            cursor: not-allowed;
        }

    }
</style>