<template>
    <el-dialog 
        :modal-append-to-body="false" 
        title="编辑企业历程" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-form
                :model="formInfo"
                ref="ruleFormedit"
                label-width="100px"
                class="form_con"
                :rules="rules"
            >
                <el-form-item label="年份" 
                prop="courseYear"
                >
                    <el-date-picker
                        type="year"
                        placeholder="请选择年份"
                        format="yyyy"
                        value-format="yyyy"
                        style="width: 100%"
                        v-model="formInfo.courseYear"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="历程详情"
                    prop="companyCourse"
                >
                    <el-input 
                        v-model="formInfo.companyCourse"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { companyEdit } from '@/api/mobileWeb.js';

    export default{
        data(){
            return {
                dialogVisible: false,
                courseId: '',
                formInfo:{
                    courseYear: '',
                    companyCourse: ''
                },
                load: false,
                rules: {
                    courseYear: [{ required: true, message: '请选择年份', trigger: 'change' }],
                    companyCourse: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                },
            }
        },
        methods:{
            init(data){
                this.setDate(data);
                this.dialogVisible = true;
            },
            setDate(data){
                this.courseId = data.courseId;
                this.formInfo = {
                    courseYear: data.courseYear,
                    companyCourse: data.companyCourse,
                };
            },
            close(){
                this.courseId = '';
                this.formInfo = {
                    courseYear: '',
                    companyCourse: '',
                };
                this.dialogVisible = false;
            },
            save(){
                this.$refs.ruleFormedit.validate((valid)=>{
                    if(valid){
                        let opts = {
                            ...this.formInfo,
                            courseId: this.courseId
                        };
                        this.load = true;
                        companyEdit(opts).then(res=>{
                            if(res.code == 200){
                                this.$message.success(res.message);
                                this.load = false;
                                this.$emit('success');
                                this.close();
                            }else{
                                this.$message.error(res.message);
                                this.load = false; 
                            }
                        }).catch(err=>{
                            this.load = false;
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
::v-deep{
    .el-form--inline .el-form-item__content{
        width: 500px;
    }
    .disabled .el-upload--picture-card, .disabled .el-upload--text {
        display: none;
    }
}
</style>