<template>
    <div class="intorducehistory" v-loading="loading">
        <el-form
            ref="intorducehistoryForm"
            label-width="100px"
            :model="formData"
            :rules="rules"
        >
            <div class="form_con">
                <div class="form_content" v-for="(item,index) in formData.list" :key="index">
                    <el-form-item label="年份" 
                        :rules="rules.courseYear"
                        :prop="`list.${index}.courseYear`"
                    >
                        <el-date-picker
                            type="year"
                            placeholder="请选择年份"
                            format="yyyy"
                            value-format="yyyy"
                            style="width: 100%"
                            v-model="item.courseYear"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="历程详情"
                        :rules="rules.companyCourse"
                        :prop="`list.${index}.companyCourse`"
                    >
                        <el-input 
                            v-model="item.companyCourse"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                        ></el-input>
                    </el-form-item>
                        <el-form-item>
                            <el-button v-if="item.courseId" type="primary" @click="edit(item)">编辑</el-button>
                            <el-button type="danger" :loading="delLoad" @click="del(index,item.courseId)">删除</el-button>
                        </el-form-item>
                </div>
                <div class="pl24">
                    <el-button type="primary" icon="el-icon-plus" @click="add">添加历程</el-button>
                </div>
            </div>
            <el-form-item>
                <div class="d-flex justify-content-center mt20" style="width:50%">
                    <el-button type="primary" :loading="load" @click="sure">确定</el-button>
                </div>
              </el-form-item>
        </el-form>
        
        <addhis-pop ref="addhisPop" @success="success" />
    </div>
</template>

<script>
    import { companyHis,companyAdd,companyDel } from '@/api/mobileWeb.js';
    import addhisPop from './edithis';

    export default {
        components: {
            addhisPop
        },
        data() {
            return {
                formData:{
                    list: [
                        {
                            year: '',
                            desc: ''
                        }
                    ]
                },
                load: false,
                loading: false,
                delLoad: false,
                rules: {
                    courseYear: [{ required: true, message: '请选择年份', trigger: 'blur' }],
                    companyCourse: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                }
            };
        },
        methods: {
            add(){
                this.formData.list.push({
                    courseYear: '',
                    companyCourse: ''
                })
            },
            del(index,courseId){
                if(courseId){
                    let opts = {
                        courseId
                    };
                    this.delLoad = true;
                    companyDel(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.delLoad = false;
                            this.getData();
                        }else{
                            this.$message.error(res.message);
                            this.delLoad = false; 
                        }
                    }).catch(err=>{
                        this.delLoad = false;
                    })
                }else{
                    this.formData.list.splice(index,1)
                }
            },
            edit(item){
                this.$refs.addhisPop.init(item);
            },
            sure(){
                this.$refs.intorducehistoryForm.validate((valid)=>{
                    if(valid){
                        let params = this.formData.list.filter(v=>!v.courseId);
                        let listCompanyCourse = params.map(v=>{
                            return {
                                companyId: localStorage.getItem('companyId'),
                                courseYear: v.courseYear,
                                companyCourse: v.companyCourse
                            }
                        })
                        let opts = {
                            listCompanyCourse,
                        };
                        this.load = true;
                        companyAdd(opts).then(res=>{
                            if(res.code == 200){
                                this.$message.success(res.message);
                                this.load = false;
                                this.getData();
                            }else{
                                this.$message.error(res.message);
                                this.load = false; 
                            }
                        }).catch(err=>{
                            this.load = false;
                        })
                    };
                })
            },
            success(){
                this.getData();
            },
            getData(){
                this.loading = true;
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                };
                companyHis(opts).then(res=>{
                    if(res.code == 200){
                        res.data.forEach(v=>{
                            v.courseYear = v.courseYear.toString();
                        })
                        this.formData.list = res.data;
                    }else{
                        this.formData = {
                            list: [
                                {
                                    courseYear: '',
                                    companyCourse: ''
                                }
                            ]
                        };
                    }
                    this.loading = false;
                    this.$refs.intorducehistoryForm.clearValidate();
                }).catch(err=>{
                    this.loading = false;
                })
            }
        },
        mounted() {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
    .intorducehistory{
        width: 100%;
        padding-top: 24px;
        .form_con{
            width: 50%;
            min-height: 65vh;
        }
        .form_content{
            width: 100%;
        }
    }
</style>