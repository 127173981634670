<template>
    <div class="partnerCon" v-loading="loading">
        <div class="partner_list d-flex">
            <template v-if="list.length">
                <div class="image_box" v-for="(item,index) in list" :key="index">
                    <div class="img_con">
                        <el-image 
                        class="img"
                        :src="item.partnerImg" 
                        :preview-src-list="[item.partnerImg]"
                    >
                    </el-image>
                    <div class="options d-flex">
                        <i class="el-icon-edit-outline" @click="edit(item)"></i>
                        <i class="el-icon-delete" @click="del(item.partnerId)"></i>
                    </div>
                    </div>
                </div>
            </template>
            <div @click="add">
                <el-upload
                    :action="uploadUrl"
                    multiple	
                    list-type="picture-card"
                    disabled
                >
                <div class="cus_add d-flex">
                    <i class="el-icon-plus"></i>
                    <div class="add_text">添加加合作伙伴logo</div>
                </div>
            </el-upload>
            </div>
        </div>

        <add-pop ref="addPop" @success="success"/>
    </div>
</template>

<script>
    import { queryPartner,delPartner } from '@/api/mobileWeb.js';
    import addPop from './add';

    export default {
        components: {
            addPop
        },
        data() {
            return {
                uploadUrl:this.$store.state.uploadingUrl,
                loading: false,
                list: [],
            };
        },
        methods: {
            add(){
                this.$refs.addPop.init('add');
            },
            edit(item){
                let data = JSON.parse(JSON.stringify(item))
                this.$refs.addPop.init('edit',data);
            },
            success(){
                this.getData();
            },
            del(id){
                let opts = {
                    partnerId: id
                };
                delPartner(opts).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.getData();
                    }else{
                        this.$message.error(res.message);
                    }
                }).catch(err=>{
                })
            },
            getData(){
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                };
                this.loading = true;
                queryPartner(opts).then(res=>{
                    if(res.code == 200){
                        this.list = res.data;
                        this.loading = false;
                    }else{
                        this.list = [];
                        this.loading = false;
                    }
                }).catch(err=>{
                    this.loading = false;
                })
            },
        },
        mounted() {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
    .partnerCon{
        width: 100%;
        padding: 24px;

        .partner_list{
            width: 100%;
            flex-wrap: wrap;

            .image_box{
                width: 146px;
                height: 146px;
                border-radius: 6px;
                margin: 0 20px 20px 0;
                position: relative;
                border: 1px dashed rgb(81, 203, 205);
                padding: 48px 0 48px;
                box-sizing: border-box;
                .img_con{
                    width: 100%;
                    height: 100%;
                }
                ::v-deep{
                    .img{
                        width: 100%;
                        height: 100%;
    
                        .el-image__inner{
                            width: 100%;
                            height: auto;
                            //object-fit: contain;
                        }
                    }
                }
                .options{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 10;
                    i{
                        cursor: pointer;
                        color: rgb(81, 203, 205);
                        &:first-child{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .cus_add{
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .add_text{
                height: 18px;
                line-height: 18px;
                font-size: 14px;
                color: #8c939d;
                margin-top: 5px;
            }
        }
    }
</style>