<template>
    <div class="mobileweb_con">
        <el-tabs v-model="activeName" @tab-click="changeTab">
            <el-tab-pane label="轮播图管理" name="first">
                <banner-con />
            </el-tab-pane>
            <!-- <el-tab-pane label="企业介绍" name="second">
                <intorduce-con ref="intorduceCon"/>
            </el-tab-pane> -->
            <el-tab-pane label="企业介绍" name="second">
                <intorducedesc-con ref="intorduceCon"/>
            </el-tab-pane>
            <el-tab-pane label="企业历程" name="third">
                <intorduce-history />
            </el-tab-pane>
            <el-tab-pane label="产品介绍" name="fourth">
                <product-con />
            </el-tab-pane>
            <el-tab-pane label="服务案例" name="fifth">
                <servicecases-con />
            </el-tab-pane>
            <el-tab-pane label="企业视频" name="sixth">
                <video-con />
            </el-tab-pane>
            <el-tab-pane label="企业招聘" name="seventh">
                <recruit-con />
            </el-tab-pane>
            <el-tab-pane label="企业动态" name="nineth">
                <dynamic-con />
            </el-tab-pane>
            <el-tab-pane label="合作伙伴" name="tenth">
                <partner-con />
            </el-tab-pane>
            <el-tab-pane label="我们的服务" name="eleventh">
                <weservice-con />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import bannerCon from './components/banner/bannercon';
    // import intorduceCon from './components/intorduce/intorduce';
    import intorducedescCon from './components/intorduce/intorducedesc';
    import intorduceHistory from './components/intorduce/intorducehistory';
    import productCon from './components/product/product';
    import servicecasesCon from './components/servicecases/servicecases';
    import videoCon from './components/video/videocon';
    import recruitCon from './components/recruit/recruitcon';
    import dynamicCon from './components/dynamic/dynamiccon';
    import partnerCon from './components/partner/partner';
    import weserviceCon from './components/weservice/weservice';
    import { queryHy } from '@/api/mobileWeb.js';
import {Quill} from "vue-quill-editor";

    export default{
        components:{
            bannerCon,
            // intorduceCon,
            intorducedescCon,
            intorduceHistory,
            productCon,
            servicecasesCon,
            videoCon,
            recruitCon,
            dynamicCon,
            partnerCon,
            weserviceCon
        },
        data(){
            return {
                activeName: 'first',
            }
        },
        methods:{
            getHy(){
                let opts = {isDel:0};
                queryHy(opts).then(res=>{
                    if(res.code == 200){
                        if(res.data.length){
                            this.$store.commit('setTrade',res.data);
                        };
                    }
                })
            },
            changeTab(e){
                if(e.paneName == 'second'){
                    this.$refs.intorduceCon.restMap();
                }else{
                    this.$refs.intorduceCon.restDefaultMap();
                }
            },
        },
        mounted(){
            this.getHy();
        }
    }
</script>

<style lang="scss" scoped>
.mobileweb_con{
    width: 100%;
    min-height: 100%;
    background: #fff;
}
</style>
