<template>
    <el-dialog 
        :modal-append-to-body="false" 
        :title="type == 'add' ? '添加产品' : '编辑产品'" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-form
                :model="formInfo"
                ref="ruleFormpadd"
                label-width="100px"
                class="form_con"
                :rules="rules"
            >
                <el-form-item label="产品名称" prop="productName">
                    <el-input placeholder="请输入产品名称" v-model="formInfo.productName"></el-input>
                </el-form-item>
                <el-form-item label="产品主图" prop="productImg">
                    <el-upload
                        :action="uploadUrl"
                        :class="{ disabled: fileList.length > 0 }"
                        :file-list="fileList"
                        list-type="picture-card"
                        :limit="1"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input placeholder="排序越大越靠前" v-model="formInfo.sort"></el-input>
                </el-form-item>
                <el-form-item label="显示状态">
                    <el-switch v-model="formInfo.status"></el-switch>
                </el-form-item>
                <el-form-item label="产品介绍" prop="content">
                    <quill-editor
                        style="width: 100%"
                        ref="myQuillEditorOne"
                        :options="editorOptionOne"
                        v-model="formInfo.content"
                    />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { editProduct, addProduct } from '@/api/mobileWeb.js';
    import { quillEditor, Quill } from "vue-quill-editor";
    import resizeImage from "quill-image-resize-module";
    import { ImageDrop } from "quill-image-drop-module";
    import quillConfig from "@/utils/quill-config.js";
    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/resizeImage ", resizeImage);
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";

    export default {
        components:{
            quillEditor
        },
        data() {
            return {
                dialogVisible: false,
                type:'add',
                productId: '',
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                formInfo:{
                    productName: '',
                    productImg: '',
                    status: 1,
                    sort: '',
                    content: '',
                },
                fileList:[],
                load: false,
                rules: {
                    productName: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
                    productImg: [{ required: true, message: "请上传产品主图", trigger: "blur" }],
                    content: [{ required: true, message: "请输入产品介绍", trigger: "blur" }],
                },
                editorOptionOne:{
                    ...quillConfig,
                    placeholder:"请输入产品介绍"
                },
            };
        },
        methods: {
            init(type,data){
                this.type = type;
                if(type == 'edit'){
                    this.setData(data);
                }else{
                    this.formInfo.status = this.formInfo.status == 0 ? true : false;
                };
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                this.fileList = [];
                this.formInfo.productImg = '';
            },
            handleSuccess(response, file, fileList) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.fileList = fileList;
                this.formInfo.productImg = this.ossUrl +  file.response.data;
            },
            close(){
                this.productId = '';
                this.formInfo = {
                    productName: '',
                    productImg: '',
                    status: 1,
                    sort: '',
                    content: '',
                };
                this.fileList = [];
                this.dialogVisible = false;
            },
            save(){
                this.$refs.ruleFormpadd.validate((valid)=>{
                    if(valid){
                        this.formInfo.status = this.formInfo.status ? 0 : 1;
                        let opts = {
                            ...this.formInfo,
                            companyId: localStorage.getItem('companyId'),
                        };
                        this.load = true;
                        if(this.type == 'edit'){
                            opts.productId = this.productId;
                            editProduct(opts).then(res=>{
                                if(res.code == 200){
                                    this.$message.success(res.message);
                                    this.load = false;
                                    this.$emit('success');
                                    this.close();
                                }else{
                                    this.$message.error(res.message);
                                    this.load = false; 
                                }
                            }).catch(err=>{
                                this.load = false; 
                            })
                        }else{
                            addProduct(opts).then(res=>{
                                if(res.code == 200){
                                    this.$message.success(res.message);
                                    this.load = false;
                                    this.$emit('success');
                                    this.close();
                                }else{
                                    this.$message.error(res.message);
                                    this.load = false; 
                                }
                            }).catch(err=>{
                                this.load = false; 
                            })
                        }
                    }
                })
            },
            setData(data){
                data.status = data.status ? 0 : 1;
                this.productId = data.productId;
                this.formInfo = {
                    productName: data.productName,
                    productImg: data.productImg,
                    status: data.status == 0 ? true : false,
                    sort: data.sort,
                    content: data.content,
                };

                this.fileList = [{
                    url: data.productImg,
                    uid: 1,
                    response:{
                        data: data.productImg.slice(this.findStr(data.productImg,'/',2) + 1)
                    }
                }]; 
            },
            findStr(str,cha,num){
				let x=str.indexOf(cha);
				for(let i=0;i<num;i++){
					x=str.indexOf(cha,x+1);
				}
				return x;
			},
        },
        mounted() {

        },
    }
</script>

<style lang="scss" scoped>
::v-deep{
    .disabled .el-upload--picture-card, .disabled .el-upload--text {
        display: none;
    }
}
</style>