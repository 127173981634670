const educatList = [
    { title: '学历不限', id: 1 }, { title: '初中及以下', id: 2 }, { title: '中专/中技', id: 3 },
    { title: '高中', id: 4 }, { title: '大专', id: 5 }, { title: '本科', id: 6 },
    { title: '硕士', id: 7 }, { title: '博士', id: 8 }
];

const experienceArray = [
    { title: '经验不限', id: 7 }, { title: '应届生', id: 1 }, { title: '1年以内', id: 2 },
    { title: '1-3年', id: 3 }, { title: '3-5年', id: 4 }, { title: '5-10年', id: 5 },
    { title: '10年以上', id: 6 }
];

const multiArray = [
    ['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
    ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
];

export {
    educatList,
    experienceArray,
    multiArray
};