<template>
    <div class="bannercon">
        <div class="header_con d-flex">
            <el-button type="primary" @click="add">添加招聘</el-button>
            <template v-if="isTel">
                <el-button type="primary" @click="addTel">HR电话</el-button>
            </template>
            <template v-else>
                <div class="telcon d-flex">
                    <div class="con">
                        <div class="tel">{{recruitPhone}}</div>
                        <div class="text">HR电话</div>
                    </div>
                    <el-button @click="editTel">修改</el-button>
                </div>
            </template>
        </div>
        <common-table
            :tableData="tableData"
            :loading="loading"
            :total="total"
            @handleCurrentChange="CurrentChange"
            @handleSizeChange="SizeChange"
            :currentPage="currentPage"
        >
           <template slot="table">
                <el-table-column prop="position" align="center" label="职位名称" />
                <el-table-column align="center" label="行业">
                    <template slot-scope="scope">
                        {{ scope.row.positionIndustryId | filterPosi }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="经验要求">
                    <template slot-scope="scope">
                        {{ scope.row.experience | filterExperience}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="最低学历">
                    <template slot-scope="scope">
                        {{ scope.row.education | filterEducat}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="薪资范围">
                    <template slot-scope="scope">
                        {{scope.row.minSalary/1000/100}}k-{{scope.row.maxSalary/1000/100}}k
                    </template>
                </el-table-column>
                <el-table-column prop="recruitAddress" align="center" label="工作地址" />
                <el-table-column label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
                        <el-button class="delColor" size="mini" type="text" @click="del(scope.row.recruitId)">删除</el-button>
                    </template>
                </el-table-column>
           </template>
        </common-table>

        <add-pop ref="addPop" @success="success" />
        <add-phone ref="addPhone" @success="successTel" />
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import addPop from './add';
    import addPhone from './addphone';
    import { queryRecruirt,delRecruirt } from '@/api/mobileWeb.js';
    import { educatList,experienceArray } from './data.js';
    import { fetchCompany } from '@/api/companyManage.js';
    import store from '@/store';

    const filterEducat = (id) => {
        let item = educatList.filter(v => v.id == id)[0];
        return item?.title;
    };

    const filterExperience = (id) => {
        let item = experienceArray.filter(v => v.id == id)[0];
        return item?.title;
    };

    const filterPosi = (id) => {
        let data = store.state.tradeList;
        if(data && data.length){
            let item = data.filter(v => v.industryId == id)[0];
            if(item){
                return item.industryName;
            };
        };
        return '';
    };

    export default {
        components:{
            commonTable,
            addPop,
            addPhone
        },
        data(){
            return {
                tableData:[],
                total:0,
                pageNum:1,
                pageSize:10,
                loading:false,
                isTel: true,
                recruitPhone: '',
                currentPage: 1,
            }
        },
        filters:{
            filterEducat,
            filterExperience,
            filterPosi
        },
        methods:{
            addTel(){
                this.$refs.addPhone.init('add');
            },
            editTel(){
                this.$refs.addPhone.init('edit',this.recruitPhone);
            },
            add(){
                this.$refs.addPop.init('add');
            },
            edit(row){
                this.$refs.addPop.init('edit',row);
            },
            CurrentChange(num){
                this.pageNum = num;
                this.getList();
            },
            SizeChange(num){
                this.pageSize = num;
                this.pageNum = 1;
                this.currentPage = 1;
                this.getList();
            },
            del(recruitId){
                let opts = {recruitId};
                delRecruirt(opts).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.getList();
                    }else{
                        this.$message.success(res.message);
                    }
                });
            },
            getList(){
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                };
                this.loading = true;
                try{
                    queryRecruirt(opts).then(res=>{
                        if(res.code == 200){
                            let { pageInfo } = res.data;
                            if(pageInfo.list.length){
                                this.tableData = pageInfo.list;
                            };
                            this.total = pageInfo.total;
                            this.loading = false;
                        }else{
                            this.tableData = [];
                            this.loading = false;
                        }
                    }).catch(err=>{
                        this.loading = false;
                    })
                }catch(err){
                    this.loading = false;
                }
            },
            success(){
                this.getList();
            },
            successTel(){
                this.getTel(); 
            },
            getTel(){
                let opts = {
                    companyId: localStorage.getItem('companyId'),
                };
                fetchCompany(opts).then(res=>{
                    if(res.code == 200){
                        if(res.data.recruitPhone){
                            this.recruitPhone = res.data.recruitPhone;
                            this.isTel = false;
                        }else{
                            this.isTel = true;
                        }
                    }
                })
            },
        },
        mounted(){
            this.getList();
            this.getTel();
        },
    }
</script>

<style lang="scss" scoped>
    .bannercon{
        width: 100%;
        padding: 24px;
    }

    .telcon{
        margin-left: 20px;
        .con{
            margin-right: 10px;
            .tel{
                font-size: 18px;
                font-weight: bold;
                color: #333333;
            }
            .text{
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                opacity: 0.64;
            }
        }
    }
</style>